import { useQuery } from "react-query";

import { $apis } from "../services/request";
import { ReqURL } from "../utils/paths";
import {
  DefinitionType,
  ServiceType,
  SuggestionDataType,
  SuggestionType,
} from "../utils/types/allTypes";
import { queryName } from "./queryNames";
import { message } from "antd";

// GET ALL SUGGESTIONS
export function useAllSuggestion(pageNumber: number, search?: string) {
  return useQuery<SuggestionType>(
    [queryName.SUGGESTION, pageNumber, search],
    async () => {
      const res = await $apis.get(
        `${ReqURL.SUGGESTION}?page=${pageNumber}${
          search ? `&search=${search}` : ""
        }`
      );
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

// GET ALL SERVICES
export function useAllService(page?: number, search?: string, all?: boolean) {
  return useQuery<ServiceType>(
    [queryName.SERVICES, page, all, search],
    async () => {
      const res = await $apis.get(
        `${ReqURL.SERVICE}${page ? `?page=${page}` : ""}${
          all ? `?all=${all}` : ""
        }${search ? `&search=${search}` : ""}`
      );
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

// GET ONE ID SUGGESTION
export function useSuggestionOne(id: number) {
  return useQuery<SuggestionDataType>(
    [queryName.SUGGESTION_ONE, id],
    async () => {
      const res = await $apis.get(ReqURL.SUGGESTION + `/${id}`);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

// GET ALL DEFINITION
export function useDefinitions() {
  return useQuery<DefinitionType[]>(
    [queryName.DEFINITION],
    async () => {
      const res = await $apis.get(ReqURL.DEFINITION);
      return res.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => message.warning("Tizimda xatolik yuz berdi"),
    }
  );
}
