import { Button, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import CloseIcon from "../../assets/icons/CloseIcon";

import { editServiceModalAtom } from "../../atoms/Atoms";
import { useUpdateService } from "../../queries/mutations";
import { localeFormatter } from "../../utils/helper/localeFormatter";
import { removeNaN } from "../../utils/helper/removeNaN";
import style from "./Service.module.scss";

interface EditModalType {
  data?: {
    id: number;
    name: string;
    price: number;
    key: number;
    description: string
  };
}

const EditService: React.FC<EditModalType> = ({ data }) => {
  const editServiceMutate = useUpdateService(data?.id!);
  const openModalValue = useRecoilValue(editServiceModalAtom);
  const setOpenModal = useSetRecoilState(editServiceModalAtom);
  const [form] = Form.useForm();

  // cancel modal
  const cancelModal = () => {
    setOpenModal(false);
  };

  // previous information
  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
      price: localeFormatter(`${data?.price}`),
      description: data?.description
    });
  }, [data, form]);

  const changePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.value;
    form.setFieldValue("price", localeFormatter(field));
  };

  // finish editing
  const finishing = (e: any) => {
    const data: any = {
      name: e.name,
      price: +removeNaN(e.price),
      description: e.description
    };
    editServiceMutate.mutate(data);
  };

  return (
    <Modal
      open={openModalValue}
      onCancel={cancelModal}
      className={style.service_modal}
      forceRender
      closeIcon={<CloseIcon />}
    >
      <h3>Xizmatni tahrirlash</h3>
      <Form
        form={form}
        autoComplete="off"
        onFinish={finishing}
        layout="vertical"
        name="editService"
        className={style.form}
      >
        <Form.Item
          name="name"
          label="Xizmat nomi"
          rules={[
            {
              required: true,
              message: "Iltimos, xizmat nomini kiriting !"
            }
          ]}
        >
          <Input className={style.name} placeholder="Xizmat nomini kiriting" />
        </Form.Item>
        <Form.Item
          name="price"
          label="Xizmat narxi ($)"
          rules={[
            {
              required: true,
              message: "Iltimos, xizmat narxini kiriting !"
            }
          ]}
        >
          <Input
            onChange={changePrice}
            className={style.price}
            suffix={<>$</>}
            placeholder="Xizmat narxini kiriting"
          />
        </Form.Item>
        <Form.Item label="Xizmat haqida" name="description" rules={[
          {
            required: true,
            message: "Iltimos, xizmat haqida ma'lumot kiriting"
          }
        ]}>
          <Input className={style.name} placeholder="Xizmat haqida ma'lumot kiriting" />
        </Form.Item>
        <Form.Item className={style.submit}>
          <Button
            type="primary"
            htmlType="submit"
            loading={editServiceMutate.isLoading}
          >
            Saqlash
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditService;
