import { Button, Empty, Input, Popconfirm, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LoadingOutlined } from "@ant-design/icons";

import EditIcon from "../../assets/icons/EditIcon";
import ServiceAddIcon from "../../assets/icons/ServiceAddIcon";
import {
  addServiceModalAtom,
  editServiceModalAtom,
  userRole,
} from "../../atoms/Atoms";
import { useAllService } from "../../queries/query";
import { ServiceDataType } from "../../utils/types/allTypes";
import PaginationNew from "../pagination/PaginationNew";
import style from "./Service.module.scss";
import AddService from "./AddService";
import EditService from "./EditService";
import SearchIcon from "../../assets/icons/SearchIcon";
import { tableNumber } from "../../utils/helper/tableNumber";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { ADMIN } from "../../utils/models/UserRoleModel";
import { useDeleteService } from "../../queries/mutations";

const Service = () => {
  const UserRole = useRecoilValue(userRole);
  const location = useLocation();
  const navigate = useNavigate();
  const deleteService = useDeleteService();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [singleService, setSingleService] = useState();
  const setEditModalChange = useSetRecoilState(editServiceModalAtom);
  const setOpenAddServiceModal = useSetRecoilState(addServiceModalAtom);
  const { data: AllService, isLoading } = useAllService(
    pageNumber,
    location.search.split("=")[1]
  );

  // open modal
  const openChangeModal = () => {
    setOpenAddServiceModal(true);
  };

  // edit service
  const editService = (e: any) => {
    setEditModalChange(true);
    setSingleService(e);
  };

  const changeDeleteService = (e: ServiceDataType) => {
    deleteService.mutate(e.id);
  };

  // columns
  let columns: ColumnsType<ServiceDataType> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, pageNumber),
      width: 80,
      className: style.center_elem,
    },
    {
      title: "Xizmat nomi",
      dataIndex: "name",
    },
    {
      title: "Xizmat haqida",
      dataIndex: "description",
      width: "20rem",
    },
    {
      title: "Xizmat narxi ($)",
      dataIndex: "price",
      render: (e: number) => (
        <span>{e > 0 ? e.toLocaleString() : "Bepul"}</span>
      ),
    },
  ];

  if (UserRole === ADMIN) {
    columns = [
      ...columns,
      ...[
        {
          title: "Tahrirlash",
          render: (e: ServiceDataType) => (
            <div className={style.center}>
              <Button onClick={() => editService(e)}>
                <EditIcon />
              </Button>
            </div>
          ),
          className: style.center_elem,
        },
        {
          title: "O'chirish",
          render: (e: ServiceDataType) => (
            <div className={style.center}>
              <Popconfirm
                placement="left"
                title="O'chirishni davom ettirasizmi ?"
                okText="Ha !"
                cancelText="Yo'q."
                okType="danger"
                onConfirm={() => {
                  changeDeleteService(e);
                }}
              >
                <Button danger className={`${style.btn} ${style.btn_delete}`}>
                  <DeleteIcon />
                </Button>
              </Popconfirm>
            </div>
          ),
          className: style.center_elem,
        },
      ],
    ];
  }

  const changeDown = (e: any) => {
    const value = e.target.value;
    if (e.key === "Enter") navigate(`${location.pathname}?search=${value}`);
  };

  // table locale element
  const tableLocale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Xizmatlar mavjud emas!"
      />
    ),
  };

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) navigate(location.pathname);
  };

  return (
    <div className={style.services}>
      <div className={style.top}>
        <h3>Xizmatlar ro'yhati</h3>
        {UserRole === ADMIN && (
          <Button onClick={openChangeModal} type="primary">
            <ServiceAddIcon /> Xizmat yaratish
          </Button>
        )}
      </div>
      <div className={style.bottom}>
        <Input
          placeholder="Nomi bo‘yicha qidirish..."
          className={style.search}
          onKeyDown={changeDown}
          onChange={changeSearch}
          suffix={<SearchIcon />}
        />
        <Table
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined />,
          }}
          dataSource={AllService?.data}
          pagination={false}
          columns={columns}
          className={style.table}
          locale={tableLocale}
          rowKey={(e) => e.id}
        />
        <PaginationNew
          paginationProps={{
            onChange: (e) => setPageNumber(e),
            total: AllService?.total,
            pageSize: 10,
            current: pageNumber,
          }}
        />
      </div>
      <AddService />
      <EditService data={singleService} />
    </div>
  );
};

export default Service;
