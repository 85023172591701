import {
  Button,
  Empty,
  Form,
  Input,
  message,
  Select,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import axios from "axios";
import { saveAs } from "file-saver";
import PdfIcon from "../../assets/icons/PdfIcon";
import { useAddSuggestion } from "../../queries/mutations";
import { useAllService, useDefinitions } from "../../queries/query";
import style from "./NewSuggestion.module.scss";
import { tableNumber } from "../../utils/helper/tableNumber";
import SaveIcon from "../../assets/icons/SaveIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { localeFormatter } from "../../utils/helper/localeFormatter";
import { removeNaN } from "../../utils/helper/removeNaN";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import { INDEX } from "../../utils/paths";
import { PdfTemplate } from "../document";
import ReactToPrint from "react-to-print";

const { Option } = Select;

type ServiceType = {
  id: number;
  name: string;
  description: string;
  price: number;
};

type SuggestionService = {
  id: number;
  name: string;
  price: number;
  description: string;
};

const NewSuggestion: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const goBackSug = () => navigate(INDEX);
  const addSuggestionReq = useAddSuggestion(goBackSug);
  const { data: AllService, isLoading } = useAllService(
    0,
    location.search.split("=")[1],
    true
  );
  const [suggestionName, setSuggestionName] = useState("");
  const [suggestionPrice, setSuggestionPrice] = useState(0);
  const [suggestionDiscount, setSuggestionDiscount] = useState<string>();
  const [suggestionService, setSuggestionService] = useState<
    SuggestionService[]
  >([]);
  const [salesman, setSalesman] = useState<string>();
  const [definitionId, setDefinitionId] = useState<number>();
  const [date, setDate] = useState<string>();
  const { data: definitionData, isLoading: isLoadingDefinition } =
    useDefinitions();

  // change services
  const changeService = (item: boolean, e: ServiceType) => {
    if (item) {
      setSuggestionPrice(
        suggestionPrice + e.price * Number(removeNaN(date ?? "1"))
      );
      setSuggestionService([...suggestionService, e]);
    } else {
      setSuggestionPrice(
        suggestionPrice - e.price * Number(removeNaN(date ?? "1"))
      );
      setSuggestionService([
        ...suggestionService.filter((arr) => arr.id !== e.id),
      ]);
    }
  };

  // table columns
  const columns: ColumnsType<ServiceType> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, 1),
      width: 80,
      className: style.center_elem,
    },
    {
      title: "Xizmat nomi",
      dataIndex: "name",
    },
    {
      title: "Xizmat nomi",
      dataIndex: "description",
    },
    {
      title: "Narxi ($)",
      dataIndex: "price",
      render: (e: number) => (
        <span>{e > 0 ? e.toLocaleString() : "Bepul"}</span>
      ),
    },
    {
      title: "Xizmatni faollashtirish",
      render: (e: ServiceType) => (
        <div className={style.center}>
          <Switch onChange={(item) => changeService(item, e)} />
        </div>
      ),
      className: style.center_elem,
    },
  ];

  // add finish suggestion
  const finishAddSuggestion = (e: { name: string; definition_id: number }) => {
    if (e.name && salesman && suggestionService.length > 0) {
      const data = {
        name: e.name,
        allPrice: suggestionPrice,
        services: suggestionService,
        discount_price: suggestionDiscount ?? "0",
        salesman,
        definition_id: e.definition_id,
        date: Number(removeNaN(date ?? "1")),
        price: suggestionPrice - Number(removeNaN(suggestionDiscount ?? "0")),
      };
      setSuggestionName(e.name);
      addSuggestionReq.mutate(data);
    } else message.info("Taklif nomini yoki vositachi nomini kiriting");
  };

  // change suggestion name
  const changeSuggestionName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuggestionName(e.target.value);
  };

  // genereted PDF
  const generetedPdf = () => {
    const data: any = {
      name: suggestionName,
      services: suggestionService,
      allPrice: suggestionPrice,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/genereted-pdf`, data)
      .then(() => {
        return axios
          .get(
            `${
              process.env.REACT_APP_BASE_URL
            }/genereted-pdf?name=${data?.name!}`,
            {
              responseType: "blob",
            }
          )
          .then((e) => {
            return e;
          });
      })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, `${suggestionName}.pdf`);
      })
      .catch(() => {
        message.warning("Yuklab olib bo'lmadi");
      });
  };

  // disabled download button
  const generetedPDFButtonDisabled = () => {
    if (
      suggestionName.length > 0 &&
      suggestionService.length > 0 &&
      salesman &&
      definitionId
    ) {
      return false;
    } else return true;
  };

  const changeDown = (e: any) => {
    const value = e.target.value;
    if (e.key === "Enter") navigate(`${location.pathname}?search=${value}`);
    if (!value) navigate(location.pathname);
  };

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) navigate(location.pathname);
  };

  // table locale element
  const tableLocale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Xizmatlar mavjud emas!"
      />
    ),
  };

  const changeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(removeNaN(value ?? "0")) >= suggestionPrice) {
      message.warning("Chegirma narxi umumiy summadan oshib ketdi !");
      setSuggestionDiscount(localeFormatter("0"));
    } else {
      setSuggestionDiscount(localeFormatter(value));
      // setSuggestionPrice()
    }
  };

  const changeSalesman = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSalesman(e.target.value);
  };

  const price = (): {
    all: number;
    service: number;
  } => {
    const definitionPrice =
      definitionData?.filter((item) => item.id === definitionId)[0].price ?? 0;

    const servicePrice = suggestionService?.reduce(
      (first, second) => first + second.price,
      0
    );

    return { all: definitionPrice + servicePrice, service: servicePrice };
  };

  const changeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDate(localeFormatter(value));
    setSuggestionPrice(
      price().all * Number(localeFormatter(value) ? removeNaN(value) : 1)
    );
  };

  const goBack = () => navigate("/");

  const onChangeDefinition = (id: number) => {
    const priceDef = definitionData?.filter((item) => item.id === id)[0]?.price;
    const prevPriceDef = definitionId
      ? definitionData?.filter((item) => item.id === definitionId)[0]?.price
      : 0;
    setSuggestionPrice(suggestionPrice - prevPriceDef! + priceDef!);
    setDefinitionId(id);
  };

  return (
    <>
      <div className={style.new_suggestion}>
        <div className={style.header}>
          <h1>
            <Button onClick={goBack}>
              <ArrowLeftIcon />
            </Button>
            Taklif yaratish
          </h1>
          <div className={style.right}>
            <Button
              htmlType="submit"
              disabled={generetedPDFButtonDisabled()}
              type="primary"
              loading={addSuggestionReq.isLoading}
              onClick={() => form.submit()}
            >
              <SaveIcon />
              Saqlash
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button
                  disabled={generetedPDFButtonDisabled()}
                  onClick={generetedPdf}
                  loading={false}
                >
                  <PdfIcon /> PDF nusxasini ko'rish
                </Button>
              )}
              content={() => document.querySelector("#pdfContent")}
            />
          </div>
        </div>
        <div className={style.top}>
          <div className={style.form}>
            <Form
              form={form}
              layout="inline"
              className={style.form_elem}
              onFinish={finishAddSuggestion}
            >
              <Form.Item name="name">
                <Input
                  placeholder="Taklif nomini kiriting"
                  onChange={changeSuggestionName}
                />
              </Form.Item>
              <Form.Item
                name="definition_id"
                rules={[
                  {
                    message: "",
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => onChangeDefinition(e)}
                  className={style.definition_select}
                  dropdownMatchSelectWidth
                  notFoundContent="Ta'riflar mavjud emas"
                  placeholder="Ta'rif turini tanlang"
                  loading={isLoadingDefinition}
                >
                  {definitionData &&
                    definitionData.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {/*<Form.Item>*/}
              {/*    <Button*/}
              {/*        htmlType="submit"*/}
              {/*        disabled={generetedPDFButtonDisabled()}*/}
              {/*        type="primary"*/}
              {/*        loading={addSuggestionReq.isLoading}*/}
              {/*    >*/}
              {/*        <SaveIcon/>*/}
              {/*        Saqlash*/}
              {/*    </Button>*/}
              {/*</Form.Item>*/}
            </Form>
          </div>
          <div className={style.price}>
            <h2>
              Taklif summasi: <span>{suggestionPrice.toLocaleString()}</span> $
            </h2>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.search}>
            <div className={style.left}>
              <label>Xizmatni qidirish: </label>
              <Input
                className={style.input}
                placeholder="Xizmat nomi bo'yicha qidirish"
                onKeyDown={changeDown}
                onChange={changeSearch}
                suffix={<SearchIcon />}
              />
            </div>
            <div className={style.center}>
              <label>Vositachi nomi: </label>
              <Input
                placeholder="Vositachi nomini kiriting"
                value={salesman}
                onChange={changeSalesman}
              />
            </div>
            <div className={style.date}>
              <label>Taklif muddati: </label>
              <Input
                placeholder="Taklif muddatini kiriting"
                value={date}
                disabled={!Boolean(definitionId)}
                onChange={changeDate}
                suffix="oy"
              />
            </div>
            <div className={style.right}>
              <label>Chegirma narx: </label>
              <Input
                placeholder="Chegirma narxi"
                onChange={changeDiscount}
                value={suggestionDiscount}
                disabled={!Boolean(suggestionPrice > 0)}
                suffix="$"
              />
            </div>
          </div>
          <Table
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined />,
            }}
            dataSource={AllService?.data}
            columns={columns}
            pagination={false}
            className={style.table}
            locale={tableLocale}
            rowKey={(e) => e.id}
          />
        </div>
      </div>
      <div
        style={{
          display: "none",
        }}
      >
        {PdfTemplate(
          suggestionName,
          suggestionPrice,
          suggestionService,
          Number(removeNaN(suggestionDiscount ?? "0")),
          date ?? "0",
          definitionId,
          definitionData
        )}
      </div>
    </>
  );
};

export default NewSuggestion;
