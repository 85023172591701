import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import "./App.scss";
import {RecoilRoot} from "recoil";
import {QueryClientProvider, QueryClient} from "react-query";

ConfigProvider.config({
    theme: {
        primaryColor: "#1C212D"
    }
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </RecoilRoot>
        </QueryClientProvider>
    </React.StrictMode>
);

reportWebVitals();
