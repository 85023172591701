import { useState } from "react";
import { useRecoilValue } from "recoil";
import type { ColumnsType } from "antd/lib/table";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, Grid, Input, Popconfirm, Table } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { userRole } from "../../atoms/Atoms";
import style from "./Suggestions.module.scss";
import EditIcon from "../../assets/icons/EditIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import PaginationNew from "../pagination/PaginationNew";
import { ADMIN } from "../../utils/models/UserRoleModel";
import { tableNumber } from "../../utils/helper/tableNumber";
import { useDeleteSuggestion } from "../../queries/mutations";
import { SuggestionDataType } from "../../utils/types/allTypes";
import { NEW_SUGGESTION, SUGGESTIONS } from "../../utils/paths";
import CreateFolderIcon from "../../assets/icons/CreateFolderIcon";
import { useAllSuggestion, useDefinitions } from "../../queries/query";

const Suggestions: React.FC = () => {
  const UserRole = useRecoilValue(userRole);
  const { sm } = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageNumber, setPagenumber] = useState<number>(1);
  const { data: suggestionData, isLoading } = useAllSuggestion(
    pageNumber,
    location.search.split("=")[1]
  );
  const deleteSuggestion = useDeleteSuggestion();
  const { data: definitions, isLoading: isLoadingDefintion } = useDefinitions();

  // genereted DATE
  const generetedDate = (e: string) => {
    const mmSS = e.slice(11, e.length - 8);
    return `${e.slice(0, 10).split("-").reverse().join(".")} ${mmSS}`;
  };

  const changeDeleteSuggestion = (e: SuggestionDataType) => {
    deleteSuggestion.mutate(e.id);
  };

  // columns
  let columns: ColumnsType<SuggestionDataType> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, pageNumber),
      width: 80,
      className: style.center_elem,
    },
    {
      title: "Taklif nomi",
      dataIndex: "name",
    },
    {
      title: "Vositachi",
      dataIndex: "salesman",
    },
    sm
      ? {
          title: "Ta'rif turi",
          dataIndex: "definition_id",
          render: (record: number) => (
            <>
              {definitions &&
                definitions.filter((item) => item.id === record)[0]?.name}
            </>
          ),
        }
      : {},
    sm
      ? {
          title: "Taklif narxi ($)",
          dataIndex: "allPrice",
          render: (e: number | undefined) => (
            <span>{e?.toLocaleString() ?? 0}</span>
          ),
        }
      : {},
    {
      title: "Umumiy",
      dataIndex: "price",
      render: (e: number) => <span>{e?.toLocaleString() ?? 0}</span>,
    },
    sm
      ? {
          title: "Chegirma ($)",
          dataIndex: "discount_price",
        }
      : {},
    sm
      ? {
          title: "Yaratilgan vaqti",
          dataIndex: "createdAt",
          render: (e: string) => <span>{generetedDate(e)}</span>,
        }
      : {},
  ];

  if (sm) {
    // delete columns[1];
    // delete columns[3];
    // delete columns[4];
    // delete columns[6];
    // delete columns[7];
  }

  if (UserRole === ADMIN) {
    columns = [
      ...columns,
      ...[
        {
          title: "Tahrirlash",
          render: (e: SuggestionDataType) => (
            <div className={style.center}>
              <Link
                className={style.btn}
                to={`${SUGGESTIONS}/${e.id}`}
                type="primary"
              >
                <EditIcon />
              </Link>
            </div>
          ),
          className: style.center_elem,
        },
        {
          title: "O'chirish",
          render: (e: SuggestionDataType) => (
            <div className={style.center}>
              <Popconfirm
                placement="left"
                title="O'chirishni davom ettirasizmi ?"
                okText="Ha !"
                cancelText="Yo'q."
                okType="danger"
                onConfirm={() => {
                  changeDeleteSuggestion(e);
                }}
              >
                <Button danger className={`${style.btn} ${style.btn_delete}`}>
                  <DeleteIcon />
                </Button>
              </Popconfirm>
            </div>
          ),
          className: style.center_elem,
        },
      ],
    ];
  }

  // change page Number
  const changePage = (e: number) => {
    setPagenumber(e);
  };

  // table locale element
  const tableLocale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Takliflar mavjud emas!"
      />
    ),
  };

  const onChangeSearch = (e: any) => {
    const value = e.target.value;
    if (e.key === "Enter") navigate(`${location.pathname}?search=${value}`);
  };

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) navigate(location.pathname);
  };

  return (
    <div className={style.suggestion}>
      <div className={style.info}>
        <div className={style.top}>
          <h3>Takliflar ro'yhati</h3>
          <Input
            placeholder="Nomi bo‘yicha qidiring..."
            className={style.search}
            onKeyDown={onChangeSearch}
            onChange={changeSearch}
            suffix={<SearchIcon />}
          />
          {UserRole === ADMIN && (
            <Link to={NEW_SUGGESTION}>
              <CreateFolderIcon /> Taklif yaratish
            </Link>
          )}
        </div>
        <div className={style.bottom}>
          <Table
            loading={{
              spinning:
                isLoading || deleteSuggestion.isLoading || isLoadingDefintion,
              indicator: <LoadingOutlined />,
            }}
            dataSource={suggestionData?.data}
            columns={columns}
            pagination={false}
            className={style.table}
            rowKey={(e) => e.id}
            locale={tableLocale}
          />
          <PaginationNew
            paginationProps={{
              current: pageNumber,
              total: suggestionData?.total,
              pageSize: 10,
              onChange: changePage,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
