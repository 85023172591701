import { Button, Form, Input } from "antd";

import LoginIcon from "../../assets/icons/LoginIcon";
import { useLogin } from "../../queries/mutations";
import style from "./Login.module.scss";

const Login = () => {
  const LoginMutate = useLogin();

  // finishing login
  const finishLogin = (e: any) => {
    LoginMutate.mutate(e);
  };

  return (
    <div className={style.login}>
      <div className={style.login_up}>
        <h1>Tizimga kirish</h1>
        <h5>Iltimos login va parolni kiriting</h5>
        <Form name="login" className="login_form" onFinish={finishLogin}>
          <Form.Item
            className="login_form_item"
            name="login"
            rules={[
              {
                required: true,
                message: "Iltimos loginni kiriting"
              }
            ]}
          >
            <Input
              className="login_form_item_login"
              placeholder="Loginni kiriting"
            />
          </Form.Item>
          <Form.Item
            className="login_form_item"
            name="password"
            rules={[
              {
                required: true,
                message: "Iltimos parolni kiritng"
              }
            ]}
          >
            <Input.Password placeholder="Parolni kiriting" />
          </Form.Item>
          <Form.Item className="login_form_item">
            <Button htmlType="submit">Kirish</Button>
          </Form.Item>
        </Form>
      </div>
      <div className={style.login_circle_topLeft}></div>
      <div className={style.login_circle_bottomLeft}></div>
      <div className={style.login_circle_bottomRight}>
        <LoginIcon />
      </div>
    </div>
  );
};

export default Login;
