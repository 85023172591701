import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Main from "./pages/Main/Main";
import Login from "./pages/Login/Login";
import { isAuth, userRole } from "./atoms/Atoms";
import { isBrowser } from "./utils/helper/isBrowser";
import ServicePage from "./pages/servicePage/ServicePage";
import DefinitionPage from "./pages/definition/DefinitionPage";
import NewSuggestionPage from "./pages/newSuggestion/NewSuggestionPage";
import EditSuggestionPage from "./pages/editSuggestion/EditSuggestionPage";
import {
  ALL,
  DEFINITION,
  ID_PARAMS,
  INDEX,
  LOGIN,
  MAIN,
  NEW_SUGGESTION,
  SERVICE,
  SUGGESTIONS,
} from "./utils/paths";
import Layout from "./layout/Layout";

function App() {
  const navigate = useNavigate();
  const token = localStorage.getItem("x-acces-token");
  const setIsAuth = useSetRecoilState(isAuth);
  const setUserRole = useSetRecoilState(userRole);

  useEffect(() => {
    setUserRole(localStorage.getItem("role") ?? "user");
    if (!token && isBrowser()) {
      navigate(LOGIN);
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }, [token, navigate, setIsAuth, setUserRole]);

  return (
    <Routes>
      <Route path={ALL} element={<Layout />} />
      <Route path={LOGIN} element={<Login />} />
    </Routes>
  );
}

export default App;
