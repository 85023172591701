import { Pagination, PaginationProps } from "antd";
import React from "react";

import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";

interface PaginationInterface {
  paginationProps?: PaginationProps;
}

const PaginationNew: React.FC<PaginationInterface> = ({ paginationProps }) => {
  return (
    <div className="pagination_new">
      <Pagination
        showSizeChanger={false}
        {...paginationProps}
        hideOnSinglePage={true}
        itemRender={(_, type, originalElement) => {
          if (type === "prev") {
            return (
              <span>
                <ArrowLeftIcon /> Oldingi
              </span>
            );
          }
          if (type === "next") {
            return (
              <span>
                Keyingi
                <ArrowRightIcon />
              </span>
            );
          }
          return originalElement;
        }}
      />
    </div>
  );
};

export default PaginationNew;
