import axios, {AxiosRequestConfig} from "axios";
import {ReqURL} from "../utils/paths";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const addToken = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("x-acces-token");
    config.headers!["x-acces-token"] = token;

    return config;
};

export const $api = axios.create({
    baseURL: BASE_URL
});

export const $apis = axios.create({
    baseURL: BASE_URL
});

const resetToken = async (err: any) => {
    if (
        err.response.status === 401
    ) {
        const refreshToken = localStorage.getItem("x-refresh-token");
        if (refreshToken) {
            await axios
                .get(BASE_URL + ReqURL.GENERETED_TOKEN, {
                    headers: {
                        "x-refresh-token": refreshToken
                    }
                })
                .then((data) => {
                    const datas = data.data;
                    if (datas?.newAccesToken && datas?.newRefreshToken) {
                        localStorage.setItem("x-acces-token", datas?.newAccesToken);
                        localStorage.setItem("x-refresh-token", datas?.newRefreshToken);
                        window.location.reload();
                    }
                })
                .catch((e) => {
                    if (
                        e.response.status === 400
                    ) {
                        localStorage.clear();
                        window.location.reload();
                    }
                });
        }
        else {
            window.location.href = `${window.location.origin}/login`
        }
    }
};

$apis.interceptors.request.use(addToken);
$apis.interceptors.response.use(undefined, resetToken);
