import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Empty,
  Form,
  Input,
  message,
  Select,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon";
import PdfIcon from "../../assets/icons/PdfIcon";
import SaveIcon from "../../assets/icons/SaveIcon";
import SearchIcon from "../../assets/icons/SearchIcon";
import { useUpdateSuggestion } from "../../queries/mutations";
import {
  useAllService,
  useDefinitions,
  useSuggestionOne,
} from "../../queries/query";
import { localeFormatter } from "../../utils/helper/localeFormatter";
import { removeNaN } from "../../utils/helper/removeNaN";
import { tableNumber } from "../../utils/helper/tableNumber";
import { PdfTemplate } from "../document/index";
import "../NewSuggestion/NewSuggestion.module.scss";
import style from "../NewSuggestion/NewSuggestion.module.scss";

type ServiceType = {
  id: number;
  name: string;
  price: number;
  description: string;
};

const { Option } = Select;

const EditSuggestion: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const suggestionId = +location.pathname.split("/")[2];

  const updateSuggestionMutate = useUpdateSuggestion(suggestionId);
  const { data: getOneSuggestion } = useSuggestionOne(suggestionId);
  const { data: getAllService, isLoading: LoadingService } = useAllService(
    0,
    "",
    true
  );
  const [form] = Form.useForm();

  const [suggestionPrice, setSuggestionPrice] = useState(0);
  const [suggestionName, setSuggestionName] = useState(getOneSuggestion?.name!);
  const [suggestionService, setSuggestionService] = useState<ServiceType[]>(
    getOneSuggestion?.service!
  );
  const [salesman, setSalesman] = useState<string>();
  const [discountPrice, setDiscountPrice] = useState<string>();
  const [definitionId, setDefinitionId] = useState<number>();
  const [date, setDate] = useState<string>();
  const { data: definitionData, isLoading: isLoadingDefinition } =
    useDefinitions();

  useEffect(() => {
    if (getOneSuggestion?.service.length! >= 1) {
      setSuggestionService(getOneSuggestion?.service!);
      setSuggestionName(getOneSuggestion?.name!);
    }
  }, [getOneSuggestion]);

  // change services
  const changeService = (item: boolean, e: ServiceType) => {
    if (item) {
      setSuggestionPrice(suggestionPrice + e.price);
      setSuggestionService([...suggestionService, e]);
    } else {
      setSuggestionPrice(suggestionPrice - e.price);
      setSuggestionService([
        ...suggestionService.filter((arr) => arr.id !== e.id),
      ]);
    }
  };

  // chacked service
  const checkedAll = (e: ServiceType) => {
    for (let i = 0; i < suggestionService?.length!; i++) {
      if (suggestionService[i].id === e.id) {
        return true;
      }
    }
    return false;
  };

  // columns
  const columns: ColumnsType<ServiceType> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, 1),
      width: 80,
      className: style.center_elem,
    },
    {
      title: "Xizmat nomi",
      dataIndex: "name",
    },
    {
      title: "Xizmat narxi $",
      dataIndex: "price",
      render: (e: number) => <span>{e.toLocaleString()}</span>,
    },
    {
      title: "Xizmat holati",
      render: (e: ServiceType) => (
        <Switch
          checked={checkedAll(e)}
          onChange={(item) => changeService(item, e)}
        />
      ),
    },
  ];

  // set field value suggestion name
  useEffect(() => {
    form.setFieldValue("name", getOneSuggestion?.name);
    setSuggestionPrice(getOneSuggestion?.allPrice!);
    setSalesman(getOneSuggestion?.salesman);
    setDate(String(getOneSuggestion?.date));
    setDiscountPrice(getOneSuggestion?.discount_price);
    form.setFieldValue("definition_id", getOneSuggestion?.definition_id);
    setDefinitionId(getOneSuggestion?.definition_id);
  }, [
    form,
    getOneSuggestion,
    getOneSuggestion?.allPrice,
    getOneSuggestion?.name,
    getOneSuggestion?.salesman,
    getOneSuggestion?.discount_price,
    getOneSuggestion?.definition_id,
  ]);

  // finish editing
  const finishEdit = (e: { name: string; definition_id: number }) => {
    if (!salesman || !e.name) {
      message.warning("Taklif nomi yoki vositachi nomi kiritilmagan ");
    } else {
      const data: any = {
        name: e.name,
        service: suggestionService,
        allPrice: suggestionPrice,
        salesman: salesman,
        definition_id: e.definition_id,
        date: removeNaN(date ?? "1"),
        discount_price: discountPrice ?? "0",
        price: suggestionPrice - Number(removeNaN(discountPrice ?? "0")),
      };
      updateSuggestionMutate.mutate(data);
    }
  };

  // genereted PDF
  const generetedPdf = async () => {
    // const data: any = {
    //   name: suggestionName,
    //   services: suggestionService,
    //   allPrice: suggestionPrice,
    // };
  };

  // disabled download button
  const generetedPDFButtonDisabled = () => {
    if (
      suggestionName?.length > 0 &&
      suggestionService.length > 0 &&
      salesman &&
      definitionId
    ) {
      return false;
    } else return true;
  };

  // change suggestion name
  const changeSuggestionName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuggestionName(e.target.value);
  };

  // table locale element
  const tableLocale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Xizmatlar mavjud emas!"
      />
    ),
  };

  const changeDown = (e: any) => {
    const value = e.target.value;
    if (e.key === "Enter") navigate(`${location.pathname}?search=${value}`);
    if (!value) navigate(location.pathname);
  };

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) navigate(location.pathname);
  };

  const changeSalesman = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSalesman(e.target.value);
  };

  const changeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(removeNaN(value ?? "0")) > suggestionPrice) {
      message.warning("Chegirma narxi umumiy summadan oshib ketdi !");
      setDiscountPrice(localeFormatter("0"));
    } else {
      setDiscountPrice(localeFormatter(value));
    }
  };

  const price = (): {
    all: number;
    service: number;
  } => {
    const definitionPrice =
      definitionData?.filter((item) => item.id === definitionId)[0].price ?? 0;

    const servicePrice = suggestionService?.reduce(
      (first, second) => first + second.price,
      0
    );

    return { all: definitionPrice + servicePrice, service: servicePrice };
  };

  const changeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDate(localeFormatter(value));
    setSuggestionPrice(
      price().all * Number(localeFormatter(value) ? removeNaN(value) : 1)
    );
  };

  const goBack = () => navigate("/");

  const onChangeDefinition = (id: number) => {
    const priceDef = definitionData?.filter((item) => item.id === id)[0]?.price;
    const prevPriceDef = definitionId
      ? definitionData?.filter((item) => item.id === definitionId)[0]?.price
      : 0;
    setSuggestionPrice(suggestionPrice - prevPriceDef! + priceDef!);
    setDefinitionId(id);
  };

  return (
    <>
      <div className={style.new_suggestion} id="new-suggestion">
        <div className={style.header}>
          <h1>
            <Button onClick={goBack}>
              <ArrowLeftIcon />
            </Button>
            Taklif yaratish
          </h1>
          <div className={style.right}>
            <Button
              htmlType="submit"
              disabled={generetedPDFButtonDisabled()}
              type="primary"
              loading={updateSuggestionMutate.isLoading}
              onClick={() => form.submit()}
            >
              <SaveIcon />
              Saqlash
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button
                  disabled={generetedPDFButtonDisabled()}
                  onClick={generetedPdf}
                  loading={false}
                >
                  <PdfIcon /> PDF nusxasini ko'rish
                </Button>
              )}
              content={() => document.querySelector("#pdfContent")}
            />
          </div>
        </div>
        <div className={style.top}>
          <div className={style.form}>
            <Form
              layout="inline"
              className={style.form_elem}
              form={form}
              onFinish={finishEdit}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  placeholder="Taklif nomini kiriting"
                  onChange={changeSuggestionName}
                />
              </Form.Item>
              <Form.Item
                name="definition_id"
                rules={[
                  {
                    message: "",
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(e) => onChangeDefinition(e)}
                  className={style.definition_select}
                  dropdownMatchSelectWidth
                  notFoundContent="Ta'riflar mavjud emas"
                  placeholder="Ta'rif turini tanlang"
                  loading={isLoadingDefinition}
                >
                  {definitionData &&
                    definitionData.map((item, index) => (
                      <Option value={item.id} key={index}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
          <div className={style.price}>
            <h2>
              Jami summa: <span>{suggestionPrice?.toLocaleString()}</span> $
            </h2>
          </div>
        </div>
        <div className={style.bottom}>
          <div className={style.search}>
            <div className={style.left}>
              <label>Xizmatni qidirish: </label>
              <Input
                className={style.input}
                placeholder="Xizmat nomi bo'yicha qidirish"
                onKeyDown={changeDown}
                onChange={changeSearch}
                suffix={<SearchIcon />}
              />
            </div>
            <div className={style.center}>
              <label>Vositachi nomi: </label>
              <Input
                placeholder="Vositachi nomini kiriting"
                value={salesman}
                onChange={changeSalesman}
              />
            </div>
            <div className={style.date}>
              <label>Taklif muddati: </label>
              <Input
                placeholder="Taklif muddatini kiriting"
                value={date}
                onChange={changeDate}
                suffix="oy"
              />
            </div>
            <div className={style.right}>
              <label>Chegirma narx: </label>
              <Input
                placeholder="Chegirma narxini kiriting"
                onChange={changeDiscount}
                value={discountPrice}
                disabled={suggestionService?.length === 0}
                suffix={<>$</>}
              />
            </div>
          </div>
          <Table
            dataSource={getAllService?.data}
            columns={columns}
            loading={{
              spinning: LoadingService,
              indicator: <LoadingOutlined />,
            }}
            pagination={false}
            locale={tableLocale}
            rowKey={(e) => e.id}
          />
        </div>
      </div>
      <div
        style={{
          display: "none",
        }}
      >
        {PdfTemplate(
          suggestionName,
          suggestionPrice,
          suggestionService,
          Number(removeNaN(discountPrice ?? "0")),
          date ?? "1",
          definitionId,
          definitionData
        )}
      </div>
    </>
  );
};

export default EditSuggestion;
