import React from "react";

const LoginIcon = () => {
  return (
    <svg
      //   width="500"
      //   height="500"
      //   viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M405.688 70C509.188 19 458.5 45.5 588.188 0V569.5H0C0 530.833 4.8961 478.274 66.6878 442.5C104.688 420.5 276.167 422.167 319 394.5C388.833 372.333 309.188 149.5 405.688 70Z"
        fill="#274FC7"
      />
    </svg>
  );
};

export default LoginIcon;
