export const INDEX = "/";
export const LOGIN = "/login";
export const SUGGESTIONS = "/suggestion";
export const SERVICE = "/service";
export const NEW_SUGGESTION = "/new-suggestion";
export const ID_PARAMS = "/:id";
export const DEFINITION = "/definition";
export const MAIN = "/main";
export const ALL = "*";

export const ReqURL = {
  LOGIN: "/login",
  SERVICE: "/service",
  SUGGESTION: "/suggestion",
  GENERETED_TOKEN: "/genereted-token",
  GENERETED_PDF: "/genereted-pdf",
  DEFINITION: "/definition",
  DEFINITION_SERVICE: "/definition-service",
};
