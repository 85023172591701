import { useState } from "react";
import { Button, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useLocation, useNavigate } from "react-router-dom";

import style from "./definition.module.scss";
import DefinitionModal from "./DefinitionModal";
import EditIcon from "../../assets/icons/EditIcon";
import { useDefinitions } from "../../queries/query";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { DefinitionType } from "../../utils/types/allTypes";
import { tableNumber } from "../../utils/helper/tableNumber";
import { useDeleteDefinition } from "../../queries/mutations";
import { paramKeys } from "../../utils/constants/paramKeys";
import { TableLocale } from "antd/lib/table/interface";
import CopyIcon from "../../assets/icons/CopyIcon";

const Definition: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [rowData, setRowData] = useState<DefinitionType | undefined>();
  const { data, isLoading } = useDefinitions();
  const deleteDefinition = useDeleteDefinition();
  const navigate = useNavigate();
  const location = useLocation();

  const onDeleteDefinition = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.stopPropagation();
    deleteDefinition.mutate(id);
  };

  const onCopyDefinition = (
    e: React.MouseEvent<HTMLElement>,
    data: DefinitionType
  ) => {
    e.stopPropagation();
    setRowData(data);
    setVisible(true);
    navigate({
      pathname: location.pathname,
      search: `?${paramKeys.CREATED}=true`,
    });
  };

  const columns: ColumnsType<DefinitionType> = [
    {
      title: "№",
      render: (_, __, index) => tableNumber(index, 1),
    },
    {
      title: "Nomi",
      dataIndex: "name",
    },
    {
      title: "Xizmatlar",
      render: (record: DefinitionType) => (
        <div>
          {record.service.map((item, key) => (
            <span className="ml_10" key={key}>
              {item}
              {key !== record.service.length - 1 ? "," : ""}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: "Xonadonlar",
      render: (record: DefinitionType) => {
        return (
          <span>
            {record.not_limit ? (
              "Cheklanmagan"
            ) : (
              <>
                {record.apartment_min} ta dan {record.apartment_max} ta gacha
              </>
            )}
          </span>
        );
      },
    },
    {
      title: "Narxi $",
      render: (record: DefinitionType) => <>{record.price?.toLocaleString()}</>,
    },
    {
      title: "",
      render: (record: DefinitionType) => (
        <div className="d_f edit_table">
          <Tooltip title="Nusxa olish" placement="left">
            <Button
              className="notBtn"
              onClick={(e) => onCopyDefinition(e, record)}
            >
              <CopyIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Tahrirlash" placement="left">
            <Button className="notBtn">
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="O'chirish" placement="left">
            <Button
              className="notBtn"
              onClick={(e) => onDeleteDefinition(e, record.id)}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  // open modal
  const onOpenModal = () => {
    setVisible(true);
    navigate({
      pathname: location.pathname,
      search: `?${paramKeys.CREATED}=true`,
    });
  };

  // table locale
  const tableLocale: TableLocale | undefined = {
    emptyText: "Ta'rif rejalar mavjud emas",
  };

  // table click row
  const onTableRow = (record: DefinitionType) => {
    return {
      onClick() {
        setRowData(record);
        setVisible(true);
        navigate({
          pathname: location.pathname,
          search: "",
        });
      },
    };
  };

  return (
    <>
      <div className={style.definition}>
        <div className={style.top}>
          <h1>Ta'rif rejalari</h1>
          <Button onClick={onOpenModal}>Ta'rif reja yaratish</Button>
        </div>
        <div className={style.bottom}>
          <Table
            locale={tableLocale}
            columns={columns}
            pagination={false}
            loading={isLoading || deleteDefinition.isLoading}
            dataSource={data}
            rowKey={(record: DefinitionType) => record.id}
            onRow={onTableRow}
          />
        </div>
      </div>
      <DefinitionModal
        visible={visible}
        setVisible={setVisible}
        data={rowData}
        setData={setRowData}
      />
    </>
  );
};

export default Definition;
