import { atom } from "recoil";
import { atomsKeys } from "./AtomsKeys";

export const addServiceModalAtom = atom({
  key: atomsKeys.OPEN_ADD_SERVICE_MODAL,
  default: false
});

export const editServiceModalAtom = atom({
  key: atomsKeys.OPEN_EDIT_SERVICE_MODAL,
  default: false
});

export const isAuth = atom({
  key: "is_auth",
  default: false
});

export const userRole = atom({
  key: "role",
  default: "user"
});
