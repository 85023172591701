import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74996 13.7708H5.24996C2.08246 13.7708 0.729126 12.4175 0.729126 9.24999V5.74999C0.729126 2.58249 2.08246 1.22916 5.24996 1.22916H6.41663C6.65579 1.22916 6.85413 1.42749 6.85413 1.66666C6.85413 1.90582 6.65579 2.10416 6.41663 2.10416H5.24996C2.56079 2.10416 1.60413 3.06082 1.60413 5.74999V9.24999C1.60413 11.9392 2.56079 12.8958 5.24996 12.8958H8.74996C11.4391 12.8958 12.3958 11.9392 12.3958 9.24999V8.08332C12.3958 7.84416 12.5941 7.64582 12.8333 7.64582C13.0725 7.64582 13.2708 7.84416 13.2708 8.08332V9.24999C13.2708 12.4175 11.9175 13.7708 8.74996 13.7708Z"
        fill="white"
      />
      <path
        d="M4.95828 10.8192C4.60244 10.8192 4.27578 10.6908 4.03661 10.4575C3.75078 10.1717 3.62828 9.7575 3.69244 9.32L3.94328 7.56417C3.98994 7.22584 4.21161 6.78834 4.45078 6.54917L9.04744 1.9525C10.2083 0.79167 11.3866 0.79167 12.5474 1.9525C13.1833 2.58834 13.4691 3.23584 13.4108 3.88334C13.3583 4.40834 13.0783 4.92167 12.5474 5.44667L7.95078 10.0433C7.71161 10.2825 7.27411 10.5042 6.93578 10.5508L5.17994 10.8017C5.10411 10.8192 5.02828 10.8192 4.95828 10.8192ZM9.66578 2.57084L5.06911 7.1675C4.95828 7.27834 4.82994 7.535 4.80661 7.68667L4.55578 9.4425C4.53244 9.61167 4.56744 9.75167 4.65494 9.83917C4.74244 9.92667 4.88244 9.96167 5.05161 9.93834L6.80744 9.6875C6.95911 9.66417 7.22161 9.53584 7.32661 9.425L11.9233 4.82834C12.3024 4.44917 12.5008 4.11084 12.5299 3.79584C12.5649 3.41667 12.3666 3.01417 11.9233 2.565C10.9899 1.63167 10.3483 1.89417 9.66578 2.57084Z"
        fill="white"
      />
      <path
        d="M11.5792 6.23416C11.5383 6.23416 11.4975 6.22833 11.4625 6.21666C9.92833 5.785 8.70916 4.56583 8.27749 3.03166C8.21333 2.79833 8.34749 2.55916 8.58083 2.48916C8.81416 2.425 9.05333 2.55916 9.11749 2.7925C9.46749 4.035 10.4533 5.02083 11.6958 5.37083C11.9292 5.435 12.0633 5.67999 11.9992 5.91333C11.9467 6.11166 11.7717 6.23416 11.5792 6.23416Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIcon;
