import styles from "./pdf.module.scss";
import softex from "../../assets/images/softex.png";
import uysot from "../../assets/images/uysot.png";
import page2 from "../../assets/images/page1-2.png";
import page3 from "../../assets/images/page2-2.png";
import page4 from "../../assets/images/page3-2.png";
import page5 from "../../assets/images/page4-2.png";
import page6 from "../../assets/images/page5-2.png";
import { DefinitionType } from "../../utils/types/allTypes";
import { removeNaN } from "../../utils/helper/removeNaN";

type ServiceType = {
  id: number;
  name: string;
  price: number;
  description: string;
};

export const PdfTemplate = (
  name: string,
  allPrice: number,
  services: ServiceType[],
  diccount: number,
  date: string | number,
  definition_id: number | undefined,
  definitions: DefinitionType[] | undefined
) => {
  const filterDefinition = definitions?.filter(
    (item) => item.id === definition_id!
  )[0];

  const servicePrice = services?.reduce(
    (first, second) => first + second?.price,
    0
  );

  // const data: { text: string[]; images: string[] }[] = [
  //   {
  //     text: [
  //       "Showroom: Визуальный осмотр комнат, положение, размеры и цены.\n",
  //       "Удобная система поиска квартир по различным параметрам",
  //       "Получить бронирование квартир от клиентов на покупку, при помощью SMS сервисов.",
  //       "Интеграция платформы с различными каналами продаж (веб сайт, телеграм)",
  //       "Состовление договоров на покупку квартир с клиентами в течение минуты\n",
  //       "Обновляйте информацию о клиенте/контракте, сохраняя при этом историю изменений",
  //       "Автоматический расчет бонусов для персонала отдела продаж",
  //     ],
  //     images: [page2],
  //   },
  //   {
  //     text: [
  //       "Автоматическое формирование любых отчетов о продажах и приходов",
  //       "Формирование отчетов по дебиторам\n",
  //       "Автоматическая отправка СМС-сообщений клиентам о ежемесячной задолженности",
  //       "Интеграция с платежными системами (Payme, Click, Apelsin)",
  //       "Отправка автоматических СМС-сообщений клиентам при осуществлении платежей",
  //       "Автоматическое формирование платежных квитанций",
  //       "Формирование отчетов по ежедневным/месячным/годовым поступлениям",
  //     ],
  //     images: [page3],
  //   },
  //   {
  //     text: [
  //       "Вы будете получать информацию о финансовом состоянии вашего бизнеса на свой телефон",
  //       "Ежедневный, месячный, годовой доход вы узнаете в разделе платежных форм",
  //       "Информация о количестве продаж в разделе жилые комплексы (ежедневно, ежемесячно, ежегодно)",
  //       "Информация о заключенных договорах и сумме ежемесячной задолженности\n",
  //       "Общие поступление в ЖК: полученные, ожидаемые и оставшиеся суммы\n",
  //     ],
  //     images: [page4],
  //   },
  // ];

  const oneMonthPrice = (): string | undefined => {
    if (Number(removeNaN(date as string)) === 1) {
      return (allPrice - diccount).toLocaleString();
    }

    return (filterDefinition?.price! + servicePrice)?.toLocaleString();
  };

  const oneMonthDiscount = (): boolean => {
    if (Number(removeNaN(date as string)) === 1) {
      return true;
    } else return false;
  };

  return (
    <div
      id="pdfContent"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <img src={uysot} alt="uysot" />
          </div>
          <h1>Tijorat taklifi</h1>
          <div className={styles.circle1}></div>
          <div className={styles.circle2}></div>
          <div className={styles.circle3}></div>
        </div>
        <div className={styles.right}>
          <img src={softex} alt="softex" />
        </div>
      </div>
      {/*<div className={styles.page}>*/}
      {/*    <div className={styles.container}>*/}
      {/*            <h1>Повысьте эффективность отдела продаж</h1>*/}
      {/*        <div className={styles.contents}>*/}
      {/*            <div className={styles.left}>*/}
      {/*                {*/}
      {/*                    data[0].text.map((item, index) => (*/}
      {/*                        <div className={styles.item} key={index}>*/}
      {/*                            <div className={styles.line}/>*/}
      {/*                            <p>{item}*/}
      {/*                            </p>*/}
      {/*                        </div>*/}
      {/*                    ))*/}
      {/*                }*/}
      {/*            </div>*/}
      {/*            <div className={styles.right}>*/}
      {/*                {*/}
      {/*                    data[0].images.map((item, index) => (*/}
      {/*                        <img key={index} src={item} alt={item}/>*/}
      {/*                    ))*/}
      {/*                }*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className={styles.pageContainer}>
        <img src={page2} alt="" />
      </div>
      <div className={styles.pageContainer}>
        <img src={page3} alt="" />
      </div>
      <div className={styles.pageContainer}>
        <img src={page4} alt="" />
      </div>
      <div className={styles.pageContainer}>
        <img src={page5} alt="" />
      </div>
      <div className={styles.pageContainer}>
        <img src={page6} alt="" />
      </div>
      <div className={styles.pdf}>
        <h1 className={styles.title}>Narxnoma</h1>
        <div className={styles.top}>
          <h4>Xizmatlar soni</h4>
          <h2>{services?.length ?? 0} ta</h2>
        </div>
        <div className={styles.body}>
          <div className={styles.header}>
            <h2>Taklif nomi</h2>
            <h2>Asosiy funksiyalar</h2>
            <h2>Oylik to'lov</h2>
          </div>
          <div className={styles.top}>
            <div className={styles.left}>
              <h2>{name}</h2>
            </div>
            <div className={styles.center}>
              <h4>{filterDefinition?.name}</h4>
              <div className={styles.definitions}>
                {filterDefinition?.service.map((item, index) => (
                  <span key={index}>{item}</span>
                ))}
              </div>
            </div>
            <div className={styles.right}>
              {/*<h1>{(allPrice - diccount).toLocaleString()} $</h1>*/}
              {/*{diccount > 0 && (*/}
              {/*    <h1 className={styles.not}>{allPrice?.toLocaleString()} $</h1>*/}
              {/*)}*/}
              <h1>{filterDefinition?.price} $</h1>
            </div>
          </div>
          <div className={styles.bottom}>
            <h2 className={styles.add_title}>Qo'shimcha funksiyalar</h2>
            <table className={styles.table}>
              <tbody>
                {services?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>
                      {item.price === 0
                        ? "Bepul"
                        : `${item.price.toLocaleString()} $`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.bottom}>
          {/*{diccount > 0 && (*/}
          {/*    <>*/}
          {/*        <h4>Скидка</h4>*/}
          {/*        <div>*/}
          {/*            <span>{diccount?.toLocaleString()} $</span>*/}
          {/*        </div>*/}
          {/*    </>*/}
          {/*)}*/}
          <div className={styles.price}>
            <p>Jami oylik to'lov</p>
            <h3>
              <span>{oneMonthPrice()}$</span>
              <span>
                {oneMonthDiscount() && diccount !== 0 && (
                  <span className={styles.not}>
                    {allPrice.toLocaleString()} $
                  </span>
                )}
              </span>
            </h3>
          </div>
          {Number(removeNaN(date as string)) > 1 && (
            <div className={styles.price}>
              <p>Jami {date} oylik to'lov</p>
              <h3>
                <span>{(allPrice - diccount).toLocaleString()} $</span>
                {diccount !== 0 && (
                  <span className={styles.not}>
                    {allPrice.toLocaleString()} $
                  </span>
                )}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
