import {FormInstance, message} from "antd";
import {useMutation, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {
    addServiceModalAtom,
    editServiceModalAtom,
    userRole
} from "../atoms/Atoms";

import {$api, $apis} from "../services/request";
import {INDEX, ReqURL} from "../utils/paths";
import {queryName} from "./queryNames";
import React from "react";
import react from "react";

// LOGIN
export function useLogin() {
    const navigate = useNavigate();
    const setUserRole = useSetRecoilState(userRole);
    return useMutation<any>(
        async (data: any) => {
            const res = await $api.post(ReqURL.LOGIN, data);
            return res.data;
        },
        {
            onSuccess: (data) => {
                localStorage.setItem("x-acces-token", data?.token);
                localStorage.setItem("x-refresh-token", data?.refresh);
                localStorage.setItem("role", data?.role);
                setUserRole(data?.role);
                navigate(INDEX);
                message.success("Muvafaqqiyatli kirdingiz!");
            },
            onError: () => {
                message.error("Login yoki parol xato terilgan");
            }
        }
    );
}

// ADD NEW SERVICE
export function useNewService() {
    const qc = useQueryClient();
    const setOpenModal = useSetRecoilState(addServiceModalAtom);
    return useMutation(
        async (data: any) => {
            const res = await $apis.post(ReqURL.SERVICE, data);
            return res.data;
        },
        {
            onSuccess: () => {
                qc.invalidateQueries(queryName.SERVICES);
                message.success("Qo'shildi !");
                setOpenModal(false);
            },
            onError: (data) => {
                message.error(`Tizimda xatolik yuz berdi`);
            }
        }
    );
}

// UPDATE SERVICE
export function useUpdateService(id: number) {
    const qc = useQueryClient();
    const setOpenModal = useSetRecoilState(editServiceModalAtom);
    return useMutation(
        async (data) => {
            const res = await $apis.put(ReqURL.SERVICE + `/${id}`, data);
            return res.data;
        },
        {
            onSuccess: () => {
                qc.invalidateQueries(queryName.SERVICES);
                message.success("O'zgartirildi");
                setOpenModal(false);
            },
            onError: () => {
                message.error("Tizimda xatolik yuz berdi");
            }
        }
    );
}

// ADD SUGGESTION
export function useAddSuggestion(goBack: () => void) {
    return useMutation(
        async (data: any) => {
            const res = await $apis.post(ReqURL.SUGGESTION, data);
            return res.data;
        },
        {
            onSuccess: () => {
                goBack();
                message.success("Taklif yaratildi!");
            },
            onError: () => {
                message.error("Taklifni yaratishda xatolik mavjud!");
            }
        }
    );
}

// UPDATE SUGGESTION
export function useUpdateSuggestion(id: number) {
    return useMutation(
        async (data) => {
            const res = await $apis.put(ReqURL.SUGGESTION + `/${id}`, data);
            return res.data;
        },
        {
            onSuccess: () => {
                message.success("O'zgartirildi");
            },
            onError: () => {
                message.error("Tizimda xatolik yuz berdi");
            }
        }
    );
}

// genereted PDF file and download
export function useGeneretedPDF() {
    return useMutation(
        async (data) => {
            const res = await $apis.post(ReqURL.GENERETED_PDF, data);
            return res.data;
        },
        {
            onSuccess: () => {
                message.success("Fayl yuklandi!");
            },
            onError: () => {
                message.error("Faylni yuklashda xatolik mavjud!");
            }
        }
    );
}

// DELETE SUGGESTION
export function useDeleteSuggestion() {
    const qc = useQueryClient();
    return useMutation(
        async (id: number) => {
            const res = await $apis.delete(`${ReqURL.SUGGESTION}/${id}`);
            return res.data;
        },
        {
            onSuccess: () => {
                message.success("O'chirildi !");
                qc.invalidateQueries([queryName.SUGGESTION]);
            },
            onError: () => {
                message.warning("Xatolik mavjud");
            }
        }
    );
}

// DELETE SERVICE
export function useDeleteService() {
    const qc = useQueryClient();
    return useMutation(
        async (id: number) => {
            const res = await $apis.delete(`${ReqURL.SERVICE}/${id}`);
            return res.data;
        },
        {
            onSuccess: () => {
                message.success("O'chirildi !");
                qc.invalidateQueries([queryName.SERVICES]);
            },
            onError: () => {
                message.warning("Xatolik mavjud");
            }
        }
    );
}

// CREATE DEFINITION
export function useCreateDefinition(onCancel: () => void, form?: FormInstance<any>) {
    const qc = useQueryClient();
    return useMutation(
        async (data: any) => {
            const res = await $apis.post(ReqURL.DEFINITION, data);
            return res.data
        },
        {
            onSuccess: () => {
                message.success("Yaratildi");
                qc.invalidateQueries([queryName.DEFINITION]);
                onCancel();
                form?.resetFields();
            },
            onError: () => {
                message.warning("Tizimda xatolik yuz berdi, qaytadan urinib ko'ring")
            }
        }
    )
}

// DELETE DEFINITION
export function useDeleteDefinition() {
    const qc = useQueryClient();
    return useMutation(
        async (id: number) => {
            const res = await $apis.delete(`${ReqURL.DEFINITION}/${id}`);
            return res.data;
        },
        {
            onSuccess: () => {
                qc.invalidateQueries([queryName.DEFINITION]);
            },
            onError: () => {
                message.warning("Tizimda xatolik yuz berdi");
            }
        }
    )
}

// UPDATE DEFINITION
export function useUpdateDefintion(onCancel: () => void, form?: FormInstance<any>) {
    const qc = useQueryClient();
    return useMutation(
        async (body: { id: number, data: any }) => {
            const res = await $apis.put(`${ReqURL.DEFINITION}/${body.id}`, body.data);
            return res.data;
        },
        {
            onSuccess: () => {
                message.success("O'zgartirildi")
                qc.invalidateQueries([queryName.DEFINITION]);
                onCancel();
                form?.resetFields();
            },
            onError: () => {
                message.warning("Tizimda xatolik yuz berdi")
            }
        }
    )
}