import { Button } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ServiceIcon from "../../assets/icons/ServiceIcon";
import SuggestionIcon from "../../assets/icons/SuggestionIcon";
import UserIcon from "../../assets/icons/UserIcon";
import {DEFINITION, INDEX, LOGIN, SERVICE} from "../../utils/paths";
import style from "./Sidebar.module.scss";
import DefinitionIcon from "../../assets/icons/DefinitionIcon";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeLinkClassSug = () => {
    if (location.pathname === INDEX) return style.active_link;
    else return style.link;
  };

  const activeLinkClassSer = () => {
    if (location.pathname === SERVICE) return style.active_link;
    else return style.link;
  };

  const activeLinkClassDef = () => {
    if (location.pathname === DEFINITION) return style.active_link;
    else return style.link;
  };

  const logOut = () => {
    localStorage.clear();
    navigate(LOGIN);
  };

  return (
    <div className={style.sidebar}>
      <div className={style.top}>
        <h3>Offer App</h3>
        <div className={style.links}>
          <Link to={INDEX} className={activeLinkClassSug()}>
            <SuggestionIcon /> Takliflar
          </Link>
          <Link to={SERVICE} className={activeLinkClassSer()}>
            <ServiceIcon /> Xizmatlar
          </Link>
          <Link to={DEFINITION} className={activeLinkClassDef()}>
            <DefinitionIcon/>
            Ta'riflar
          </Link>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.accaunt}>
          <div className={style.user}>
            <UserIcon />
          </div>
          <div className={style.info}>
            <h5>Softex</h5>
            <p>IT Company</p>
          </div>
        </div>
        <Button
          type="primary"
          danger
          className={style.sign_out}
          onClick={logOut}
        >
          Chiqish
        </Button>
      </div>
    </div>
  );
};

export default SideBar;
