import { Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { default as React, default as react, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import DeleteIcon from "../../assets/icons/DeleteIcon";
import {
  useCreateDefinition,
  useUpdateDefintion,
} from "../../queries/mutations";
import { PRIMARY, SUBMIT } from "../../utils/constants/buttonType";
import { paramKeys } from "../../utils/constants/paramKeys";
import { localeFormatter } from "../../utils/helper/localeFormatter";
import { removeNaN } from "../../utils/helper/removeNaN";
import { DefinitionType } from "../../utils/types/allTypes";
import styles from "./definition.module.scss";

type Props = {
  setVisible: React.Dispatch<react.SetStateAction<boolean>>;
  visible: boolean;
  data: DefinitionType | undefined;
  setData: React.Dispatch<React.SetStateAction<DefinitionType | undefined>>;
};

interface FormType {
  id: number;
  name: string;
  service: {
    name: string;
  }[];
  price: string;
  apartment_max: string | number;
  apartment_min: string | number;
}

// interface ResType extends Omit<FormType, "apartment_max" | "apartment_min"> {
//   apartment_max: number;
//   apartment_min: number;
// }

const { Item, List } = Form;

const DefinitionModal: React.FC<Props> = ({
  setVisible,
  visible,
  data,
  setData,
}) => {
  const [form] = Form.useForm<FormType>();
  const location = useLocation();
  const navigate = useNavigate();
  const [apartmentCheck, setApartmentCheck] = useState<boolean>(false);

  // close modal
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setData(undefined);
    navigate({
      pathname: location.pathname,
      search: "",
    });
  };

  const createDefinition = useCreateDefinition(onCancel, form);
  const updateDefintion = useUpdateDefintion(onCancel, form);

  useEffect(() => {
    if (visible && data) {
      form.setFieldsValue({
        id: data.id,
        name: data.name,
        price: localeFormatter(`${data.price}`),
        service: [
          ...data?.service?.map((item, index) => ({
            name: item,
            key: index,
          })),
        ],
        apartment_max: localeFormatter(`${data.apartment_max}`),
        apartment_min: localeFormatter(`${data.apartment_min}`),
      });
      setApartmentCheck(data.not_limit);
    }
  }, [visible, data, form]);

  // handel fields
  const handleField = (field: string, e: string) => {
    form.setFieldValue(field, localeFormatter(e));
  };

  // add definition
  const onFinish = (e: FormType) => {
    let services = e.service.map((item) => item.name);
    const searchParams = new URLSearchParams(location.search);
    if (apartmentCheck) {
      // @ts-ignore
      delete e.apartment_max;
      // @ts-ignore
      delete e.apartment_min;
    } else {
      e.apartment_max = +removeNaN(e.apartment_max as string);
      e.apartment_min = +removeNaN(e.apartment_min as string);
    }

    if (searchParams.get(paramKeys.CREATED)) {
      createDefinition.mutate({
        ...e,
        price: +removeNaN(e.price),
        service: services,
        not_limit: apartmentCheck,
      });
    } else {
      updateDefintion.mutate({
        id: data?.id!,
        data: {
          ...e,
          price: +removeNaN(e.price),
          service: services,
          not_limit: apartmentCheck,
        },
      });
    }
  };

  // Item rules
  const rules = [
    {
      required: true,
      message: "",
    },
  ];

  return (
    <Modal
      title="Ta'rif reja yaratish"
      open={visible}
      onCancel={onCancel}
      className={styles.definition_modal}
      centered
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="name" rules={rules} label="Ta'rif reja nomi:">
          <Input required placeholder="Masalan: Uysot extra 1200" />
        </Item>
        <Row
          gutter={10}
          className="mb_10"
          justify="space-between"
          align="stretch"
        >
          <Col span={12}>
            <label>Xonadonlar soni</label>
          </Col>
          <Col>
            <Checkbox
              checked={apartmentCheck}
              onChange={(e) => setApartmentCheck(e.target.checked)}
            >
              Cheklanmagan
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Item name="apartment_min">
              <Input
                disabled={apartmentCheck}
                onChange={(e) => handleField("apartment_min", e.target.value)}
                suffix="dan"
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="apartment_max">
              <Input
                disabled={apartmentCheck}
                onChange={(e) => handleField("apartment_max", e.target.value)}
                suffix="gacha"
              />
            </Item>
          </Col>
        </Row>
        <Item name="price" rules={rules} label="Ta'rif reja narxi: ">
          <Input
            onChange={(e) => handleField("price", e.target.value)}
            required
            placeholder="Masalan: 600"
            suffix="$"
          />
        </Item>
        <List
          name="service"
          rules={[
            {
              message: "Servislar kamida 1 ta bo'lishi zarur",
              validator: async (_, value) => {
                if (value.length < 1) {
                  return Promise.reject(new Error());
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((_, index) => (
                  <div key={index} className="d_f jc_sb">
                    <Item key={index} rules={rules} name={[index, "name"]}>
                      <Input required placeholder="Masalan: CRM tizimi" />
                    </Item>
                    <Button className="notBtn" onClick={() => remove(index)}>
                      <DeleteIcon />
                    </Button>
                  </div>
                ))}
                <Item>
                  <Button onClick={() => add()} className="w_100">
                    + Servis qo'shish
                  </Button>
                </Item>
                <Form.ErrorList errors={errors} />
              </>
            );
          }}
        </List>
        <Item>
          <div className={styles.footer}>
            <Button onClick={onCancel}>Yopish</Button>
            <Button
              loading={createDefinition.isLoading || updateDefintion.isLoading}
              htmlType={SUBMIT}
              type={PRIMARY}
            >
              Saqlash
            </Button>
          </div>
        </Item>
      </Form>
    </Modal>
  );
};

export default DefinitionModal;
