import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import CloseIcon from "../../assets/icons/CloseIcon";

import { addServiceModalAtom } from "../../atoms/Atoms";
import { useNewService } from "../../queries/mutations";
import { localeFormatter } from "../../utils/helper/localeFormatter";
import { removeNaN } from "../../utils/helper/removeNaN";
import style from "./Service.module.scss";

interface AddServiceType {}

const AddService: React.FC<AddServiceType> = () => {
  const addService = useNewService();
  const setOpenModal = useSetRecoilState(addServiceModalAtom);
  const openModalValue = useRecoilValue(addServiceModalAtom);
  const [formInstance] = Form.useForm();

  // on cancel
  const cancelModal = () => {
    formInstance.resetFields()
    setOpenModal(false)
  };

  // change price
  const changePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target.value;
    formInstance.setFieldValue("price", localeFormatter(field));
  };

  // finish
  const addServiceFinish = (e: any) => {
    const data = {
      name: e.name,
      price: +removeNaN(e.price),
      description: e.description
    };
    addService.mutate(data);
    formInstance.resetFields()
  };

  return (
    <Modal
      open={openModalValue}
      onCancel={cancelModal}
      className={style.service_modal}
      closeIcon={<CloseIcon />}
    >
      <h3>Xizmat yaratish</h3>
      <Form
        layout="vertical"
        onFinish={addServiceFinish}
        form={formInstance}
        className={style.form}
      >
        <Form.Item
          name={"name"}
          label="Xizmat nomi"
          rules={[
            {
              required: true,
              message: "Iltimos, xizmat nomini kiriting"
            }
          ]}
        >
          <Input placeholder="Xizmat nomini kiriting" className={style.name} />
        </Form.Item>
        <Form.Item
          name={"price"}
          label="Narxi ($)"
          rules={[
            {
              required: true,
              message: "Iltimos, xizmat narxini kiriting"
            }
          ]}
        >
          <Input
            onChange={changePrice}
            placeholder="Xizmat narxini kiriting"
            className={style.price}
            suffix={<>$</>}
          />
        </Form.Item>
        <Form.Item label="Xizmat haqida" name="description" rules={[
          {
            required: true,
            message: "Iltimos, xizmat haqida ma'lumot kiriting"
          }
        ]}>
          <Input className={style.name} placeholder="Xizmat haqida ma'lumot kiriting" />
        </Form.Item>
        <Form.Item className={style.submit}>
          <Button
            htmlType="submit"
            type="primary"
            loading={addService.isLoading}
          >
            Yaratish
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddService;
