import React from "react";
import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import DefinitionPage from "../pages/definition/DefinitionPage";
import EditSuggestionPage from "../pages/editSuggestion/EditSuggestionPage";
import Login from "../pages/Login/Login";
import Main from "../pages/Main/Main";
import NewSuggestionPage from "../pages/newSuggestion/NewSuggestionPage";
import ServicePage from "../pages/servicePage/ServicePage";
import {
  DEFINITION,
  ID_PARAMS,
  INDEX,
  LOGIN,
  NEW_SUGGESTION,
  SERVICE,
  SUGGESTIONS,
} from "../utils/paths";

const Layout: React.FC = () => {
  return (
    <div className="main_page">
      <div className="main_page_elements">
        <SideBar />
        <Routes>
          <Route path={INDEX} element={<Main />} />
          <Route path={NEW_SUGGESTION} element={<NewSuggestionPage />} />
          <Route
            path={SUGGESTIONS + ID_PARAMS}
            element={<EditSuggestionPage />}
          />
          <Route path={DEFINITION} element={<DefinitionPage />} />
          <Route path={SERVICE} element={<ServicePage />} />
          <Route path={LOGIN} element={<Login />} />
        </Routes>
      </div>
    </div>
  );
};

export default Layout;
